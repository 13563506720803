import { useAccountV2 } from './useAccountV2';
import { useReadUserByContext } from './useReadUserByContext';

export const useIsUserOwner = () => {
  const { user, response: userResponse } = useReadUserByContext();
  const { account, response: accountResponse } = useAccountV2();

  return {
    isOwner: account?.owner?.email === user?.email,
    isFetching: userResponse.isFetching || accountResponse.isFetching,
  };
};
