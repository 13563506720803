import { LIST_AVAILABLE_REGIONS_FOR_ACCOUNT_CONTEXT } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const queryKey = ['LIST_AVAILABLE_REGIONS_FOR_ACCOUNT_CONTEXT'];

export const useListAvailableRegionsForAccountContext = () => {
  const response = useQuery({
    queryFn: () => request(LIST_AVAILABLE_REGIONS_FOR_ACCOUNT_CONTEXT),
    queryKey,
  });

  const regions = useMemo(() => {
    return response?.data?.listAvailableRegionsForAccountContext;
  }, [response?.data?.listAvailableRegionsForAccountContext]);

  return { response, regions };
};

useListAvailableRegionsForAccountContext.queryKey = queryKey;
