import * as PATHS from '@/constants/paths';
import { useAccountV2 } from '@/hooks/useAccountV2';
import { useIsUserOwner } from '@/hooks/useIsUserOwner';
import { Redirect, Route, RouteProps } from 'react-router-dom';

export const SetRegionRoute = ({ children, ...props }: RouteProps) => {
  const { isOwner, isFetching } = useIsUserOwner();
  const { account } = useAccountV2();

  // Wait for webapp-v2 to handle redirection if usesApplicationCredentials is true
  if (isFetching || account?.usesApplicationCredentials) {
    return <></>;
  }

  if (isOwner) {
    return <Route {...props}>{children}</Route>;
  }

  return (
    <Route {...props}>
      <Redirect to={PATHS.MANAGE_NO_REGION} />
    </Route>
  );
};
