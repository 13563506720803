import { queryClient } from '@/graphql/client';
import { UPDATE_REGION_FOR_ACCOUNT_CONTEXT } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useMutation } from '@tanstack/react-query';
import { PATHS } from '..';
import { useAccountV2 } from './useAccountV2';
import { useGetRegionByAccountContext } from './useGetRegionByAccountContext';

export const useUpdateRegionForAccountContext = () => {
  const updateRegionForAccountContext = useMutation({
    mutationFn: ({ regionCode }: { regionCode: string }) =>
      request(UPDATE_REGION_FOR_ACCOUNT_CONTEXT, { regionCode }),
    onSuccess: async () => {
      queryClient.invalidateQueries(useGetRegionByAccountContext.queryKey);
      queryClient.invalidateQueries(useAccountV2.queryKey);
      window.location.replace(PATHS.MANAGE_ROOT);
    },
  });

  return updateRegionForAccountContext;
};
