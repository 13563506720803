import { GET_REGION_BY_ACCOUNT_CONTEXT } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const queryKey = ['GET_REGION_BY_ACCOUNT_CONTEXT'];

export const useGetRegionByAccountContext = () => {
  const response = useQuery({
    queryFn: () => request(GET_REGION_BY_ACCOUNT_CONTEXT),
    queryKey,
    refetchOnMount: true,
  });

  const region = useMemo(() => {
    return response.data?.getRegionByAccountContext;
  }, [response.data?.getRegionByAccountContext]);

  return { response, region };
};

useGetRegionByAccountContext.queryKey = queryKey;
