import { SystemNode } from '@/constants/node';
import { FIND_SYSTEM_NODES_BY_IDS } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const getQueryKey = (systemNodeIds: string[]) => ['FIND_SYSTEM_NODES_BY_IDS', systemNodeIds];

export const useFindSystemNodesByIds = (systemNodeIds: string[]) => {
  const response = useQuery({
    enabled: systemNodeIds.length > 0,
    queryFn: () =>
      request(FIND_SYSTEM_NODES_BY_IDS, {
        systemNodeIds,
      }),
    queryKey: getQueryKey(systemNodeIds),
  });

  const systemNodes = useMemo(() => {
    return response.data?.findSystemNodesByIds as SystemNode[] ?? [];
  }, [response.data?.findSystemNodesByIds]);

  return {
    response,
    systemNodes,
  };
};

useFindSystemNodesByIds.getQueryKey = getQueryKey;
