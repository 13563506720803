import { SideBar } from '@/components/SideBar';
import { cn } from '@/utils/classname';
import { Button, Input, toast } from '@skand/ui';

import { CREATE_PORTAL_SESSION } from '@/graphql/mutations';
import { useAccount } from '@/hooks/useAccount';
import { useGetRegionByAccountContext } from '@/hooks/useGetRegionByAccountContext';
import { useMutation } from '@apollo/client';

export const SubscriptionPage = () => {
  const { account } = useAccount();
  const { region } = useGetRegionByAccountContext();

  const [createSubscriptionPortalSession, { error: accountError }] = useMutation(
    CREATE_PORTAL_SESSION,
    {
      onCompleted: async data => {
        if (data.createSubscriptionPortalSession) {
          window.open(data.createSubscriptionPortalSession);
        }
      },
      onError: e => {
        toast({
          type: 'warn',
          message: e.message,
          lifespan: 10000,
        });
      },
    },
  );

  const handleManageSubscription = async () => {
    if (!accountError) await createSubscriptionPortalSession();
  };

  return (
    <div
      className={cn(
        'h-full',
        'w-full',
        'flex',
        'flex-row',
        'flex-nowrap',
        'bg-neutral-100',
        'overflow-hidden',
      )}
    >
      <SideBar />
      <div className={cn('max-h-fit', 'w-full', 'px-6', 'overflow-auto', 'pb-8')}>
        <div
          className={cn(
            'b-b-1',
            'b-b-neutral-300',
            'b-b-solid',
            'flex',
            'items-center',
            'justify-between',
            'p-b-3',
            'p-t-30px',
          )}
        >
          <h1 className={cn('typo-heading-3', 'color-neutral-800')}>Subscription</h1>
        </div>

        <div className={cn('flex flex-col gap-4')}>
          <div className="mt-3 flex items-start justify-between">
            <p className="color-neutral-800 typo-heading-4">Organisation</p>
          </div>
          <div className="mt-2 w-[360px] flex flex-col gap-4">
            <Input label="Display name" disabled value={account?.name ?? ''} />
            <Input label="Data storage region" disabled value={region?.name ?? ''} />
            <Button
              className="cursor-pointer typo-text-s"
              filled
              onClick={() => {
                handleManageSubscription();
              }}
              primary
            >
              Manage Subscription
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
