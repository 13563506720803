import { ProjectGroup } from '@/graphql/codegen/graphql';
import { LIST_PROJECT_GROUPS_BY_ACCOUNT_CONTEXT } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

export const queryKey = ['LIST_PROJECT_GROUPS_BY_ACCOUNT_CONTEXT'];

export const useProjectGroups = () => {
  const response = useQuery({
    queryFn: () => request(LIST_PROJECT_GROUPS_BY_ACCOUNT_CONTEXT),
    queryKey,
  });

  const projectGroups: SolidId<ProjectGroup>[] = useMemo(() => {
    return (
      response.data?.listProjectGroupsByAccountContext?.filter(
        (item): item is SolidId<ProjectGroup> => !!item?.id,
      ) ?? []
    );
  }, [response.data?.listProjectGroupsByAccountContext]);

  return { response, projectGroups };
};

useProjectGroups.queryKey = queryKey;
