import * as PATHS from '@/constants/paths';
import { GROUP_DETAILS_PAGE_MODE, ROLE_DETAILS_PAGE_MODE } from '@/constants/role';
import { client, queryClient } from '@/graphql/client';
import { LogInCallback } from '@/hooks/useLogIn';
import { AccountPage } from '@/pages/AccountPages/AccountPage';
import { BrandingPage } from '@/pages/BrandingPages/BrandingPage';
import { ForgotPasswordPage } from '@/pages/ForgotPasswordPage';
import { GroupsDetailsPage } from '@/pages/GroupsPages/GroupsDetailsPage';
import { GroupsPage } from '@/pages/GroupsPages/GroupsPage';
import {
  CreateGroupsPermissionsPage,
  GroupsPermissionsPage,
} from '@/pages/GroupsPages/GroupsPermissionsPage';
import { GroupsRolesPage } from '@/pages/GroupsPages/GroupsRolesPage';
import { GroupsUsersPage } from '@/pages/GroupsPages/GroupsUsersPage';
import { LogInPage } from '@/pages/LogInPage';
import { ResetPasswordPage } from '@/pages/ResetPasswordPage';
import { RolesDetailsPage } from '@/pages/RolesPages/RolesDetailsPage';
import { RolesGroupsPage } from '@/pages/RolesPages/RolesGroupsPage';
import { RolesPage } from '@/pages/RolesPages/RolesPage';
import {
  CreateRolesPermissionsPage,
  RolesPermissionsPage,
} from '@/pages/RolesPages/RolesPermissionsPage';
import { RolesUsersPage } from '@/pages/RolesPages/RolesUsersPage';
import { SetPasswordPage } from '@/pages/SetPasswordPage';
import { SubscriptionPage } from '@/pages/SubscriptionPages/SubscriptionPage';
import { TemplatesCreatePage } from '@/pages/TemplatesPages/TemplatesCreatePage';
import { TemplatesDetailsPage } from '@/pages/TemplatesPages/TemplatesDetailsPage';
import { TemplatesEditPage } from '@/pages/TemplatesPages/TemplatesEditPage';
import { TemplatesPage } from '@/pages/TemplatesPages/TemplatesPage';
import { UnauthorizedPage } from '@/pages/UnauthorizedPage';
import { UsersDetailsPage } from '@/pages/UsersPages/UsersDetailsPage';
import { UsersGroupsPage } from '@/pages/UsersPages/UsersGroupsPage';
import { UsersPage } from '@/pages/UsersPages/UsersPage';
import { UsersPermissionsPage } from '@/pages/UsersPages/UsersPermissionsPage';
import { UsersRolesPage } from '@/pages/UsersPages/UsersRolesPage';
import { msalInstance } from '@/utils/msal';
import { config } from '@/utils/split';
import { ApolloProvider } from '@apollo/client';
import { MsalProvider } from '@azure/msal-react';
import { ToastRoot } from '@skand/ui';
import { SplitFactory } from '@splitsoftware/splitio-react';
import { QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { AdminRoute } from './AdminRoute';
import { PrivateRoute } from './PrivateRoute';
import { SetRegionPage } from '@/pages/SetRegionPage';
import { NoRegionPage } from '@/pages/NoRegionPage';
import { SetRegionRoute } from './SetRegionRoute';

export interface WebappManageRootProps {
  onLogIn?: LogInCallback;
}

export const Root = ({ onLogIn }: WebappManageRootProps) => {
  return (
    <ApolloProvider client={client}>
      <QueryClientProvider client={queryClient}>
        <MsalProvider instance={msalInstance}>
          <SplitFactory config={config}>
            <BrowserRouter>
              <Switch>
                <Route path={PATHS.RESET_PASSWORD}>
                  <ResetPasswordPage onLogIn={onLogIn} />
                </Route>
                <Route path={PATHS.FORGOT_PASSWORD}>
                  <ForgotPasswordPage />
                </Route>
                <Route path={PATHS.SET_PASSWORD}>
                  <SetPasswordPage onLogIn={onLogIn} />
                </Route>
                <Route path={PATHS.LOG_IN}>
                  <LogInPage onLogIn={onLogIn} />
                </Route>

                <PrivateRoute fallback={PATHS.LOG_IN}>
                  <Route exact path={PATHS.MANAGE_ACCOUNT}>
                    <AccountPage />
                  </Route>

                  <SetRegionRoute path={PATHS.SET_REGION}>
                    <SetRegionPage />
                  </SetRegionRoute>

                  {/* User Routes */}
                  <AdminRoute path={PATHS.MANAGE_USERS_DETAILS}>
                    <UsersDetailsPage />
                  </AdminRoute>
                  <AdminRoute path={PATHS.MANAGE_USERS_PERMISSIONS}>
                    <UsersPermissionsPage />
                  </AdminRoute>
                  <AdminRoute path={PATHS.MANAGE_USERS_GROUPS}>
                    <UsersGroupsPage />
                  </AdminRoute>
                  <AdminRoute path={PATHS.MANAGE_USERS_ROLES}>
                    <UsersRolesPage />
                  </AdminRoute>
                  <AdminRoute exact path={PATHS.MANAGE_USERS}>
                    <UsersPage />
                  </AdminRoute>

                  {/* User Group Routes */}
                  <AdminRoute exact path={PATHS.MANAGE_GROUPS}>
                    <GroupsPage />
                  </AdminRoute>

                  <AdminRoute exact path={PATHS.MANAGE_GROUPS_CREATE_DETAILS}>
                    <GroupsDetailsPage mode={GROUP_DETAILS_PAGE_MODE.CREATE} />
                  </AdminRoute>
                  <AdminRoute exact path={PATHS.MANAGE_GROUPS_CREATE_PERMISSIONS}>
                    <CreateGroupsPermissionsPage />
                  </AdminRoute>
                  <AdminRoute exact path={PATHS.MANAGE_GROUPS_CREATE_USERS}>
                    <GroupsUsersPage mode={GROUP_DETAILS_PAGE_MODE.CREATE} />
                  </AdminRoute>
                  <AdminRoute exact path={PATHS.MANAGE_GROUPS_CREATE_ROLES}>
                    <GroupsRolesPage />
                  </AdminRoute>

                  <AdminRoute exact path={PATHS.MANAGE_GROUPS_DETAILS}>
                    <GroupsDetailsPage mode={GROUP_DETAILS_PAGE_MODE.EDIT} />
                  </AdminRoute>
                  <AdminRoute exact path={PATHS.MANAGE_GROUPS_PERMISSIONS}>
                    <GroupsPermissionsPage />
                  </AdminRoute>
                  <AdminRoute exact path={PATHS.MANAGE_GROUPS_USERS}>
                    <GroupsUsersPage mode={GROUP_DETAILS_PAGE_MODE.EDIT} />
                  </AdminRoute>
                  <AdminRoute exact path={PATHS.MANAGE_GROUPS_ROLES}>
                    <GroupsRolesPage />
                  </AdminRoute>

                  {/* Role Routes */}
                  <AdminRoute exact path={PATHS.MANAGE_ROLES}>
                    <RolesPage />
                  </AdminRoute>

                  <AdminRoute exact path={PATHS.MANAGE_ROLES_CREATE_DETAILS}>
                    <RolesDetailsPage mode={ROLE_DETAILS_PAGE_MODE.CREATE} />
                  </AdminRoute>
                  <AdminRoute exact path={PATHS.MANAGE_ROLES_CREATE_PERMISSIONS}>
                    <CreateRolesPermissionsPage />
                  </AdminRoute>

                  <AdminRoute exact path={PATHS.MANAGE_ROLES_DETAILS}>
                    <RolesDetailsPage mode={ROLE_DETAILS_PAGE_MODE.EDIT} />
                  </AdminRoute>
                  <AdminRoute exact path={PATHS.MANAGE_ROLES_PERMISSIONS}>
                    <RolesPermissionsPage />
                  </AdminRoute>
                  <AdminRoute exact path={PATHS.MANAGE_ROLES_USERS}>
                    <RolesUsersPage />
                  </AdminRoute>
                  <AdminRoute exact path={PATHS.MANAGE_ROLES_GROUPS}>
                    <RolesGroupsPage />
                  </AdminRoute>

                  {/* Template Routes */}
                  <AdminRoute exact path={PATHS.MANAGE_TEMPLATES_CREATE}>
                    <TemplatesCreatePage />
                  </AdminRoute>
                  <AdminRoute path={PATHS.MANAGE_TEMPLATES_DETAILS}>
                    <TemplatesDetailsPage />
                  </AdminRoute>
                  <AdminRoute path={PATHS.MANAGE_TEMPLATES_EDIT}>
                    <TemplatesEditPage />
                  </AdminRoute>
                  <AdminRoute exact path={PATHS.MANAGE_TEMPLATES}>
                    <TemplatesPage />
                  </AdminRoute>
                  <AdminRoute exact path={PATHS.MANAGE_BRANDING}>
                    <BrandingPage />
                  </AdminRoute>
                  <AdminRoute exact path={PATHS.MANAGE_SUBSCRIPTION}>
                    <SubscriptionPage />
                  </AdminRoute>

                  <AdminRoute exact path={PATHS.MANAGE_ROOT}>
                    <Redirect to={PATHS.MANAGE_USERS} />
                  </AdminRoute>

                  <Route exact path={PATHS.MANAGE_UNAUTHORIZED}>
                    <UnauthorizedPage />
                  </Route>

                  <Route exact path={PATHS.MANAGE_NO_REGION}>
                    <NoRegionPage />
                  </Route>
                </PrivateRoute>
              </Switch>

              <ToastRoot className="z-1" size="l" />
            </BrowserRouter>
          </SplitFactory>
        </MsalProvider>
      </QueryClientProvider>
    </ApolloProvider>
  );
};
