import { SystemNode } from '@/constants/node';
import {
  ListSystemNodesByParentNodeIdQueryVariables,
  SystemNodeKindInput,
} from '@/graphql/codegen/graphql';
import { LIST_SYSTEM_NODES_BY_PARENT_NODE_ID } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const getQueryKey = (
  parentNodeId: string,
  pageSize: number,
  pageIndex: number,
  searchTerm: string,
  nodeKinds: SystemNodeKindInput[],
) => [
  'LIST_SYSTEM_NODES_BY_PARENT_NODE_ID',
  parentNodeId,
  `pageSize-${pageSize}`,
  `pageIndex-${pageIndex}`,
  `searchTerm-${searchTerm}`,
  `nodeKinds-${nodeKinds.join(',')}`,
];

export const useListSystemNodesByParentNodeIdQuery = (
  queryParam: ListSystemNodesByParentNodeIdQueryVariables,
  options?: { keepPreviousData?: boolean },
) => {
  const response = useQuery({
    queryKey: getQueryKey(
      queryParam.parentNodeId ?? 'null',
      queryParam.pageSize,
      queryParam.pageIndex,
      queryParam.searchTerm || '',
      (queryParam.nodeKinds ?? []) as SystemNodeKindInput[],
    ),
    queryFn: () =>
      request(LIST_SYSTEM_NODES_BY_PARENT_NODE_ID, {
        ...queryParam,
      }),
    keepPreviousData: options?.keepPreviousData,
  });

  const mappedResponse: {
    systemNodes: SystemNode[];
    totalNumberOfFiles: number;
    totalNumberOfPages: number;
  } = useMemo(
    () => ({
      systemNodes:
        (response.data?.listSystemNodesByParentNodeId?.data as SystemNode[])?.filter(
          (item: SystemNode): item is SolidId<SystemNode> => !!item?.id,
        ) || [],
      totalNumberOfFiles: response.data?.listSystemNodesByParentNodeId?.totalNumberOfFiles || 0,
      totalNumberOfPages: response.data?.listSystemNodesByParentNodeId?.totalNumberOfPages || -1,
    }),
    [response.data?.listSystemNodesByParentNodeId],
  );

  return { response, ...mappedResponse };
};

useListSystemNodesByParentNodeIdQuery.getQueryKey = getQueryKey;
