import { LayerFormatType } from '@/constants/layer';
import {
  Layer as LayerQuery,
  SceneEntity,
} from '@/graphql/codegen/graphql';
import {
  AnnotationGroup,
  Layer,
  LayerGroup,
  PhotoGroup,
} from '@/hooks/useSceneEntityTreeData';
import { isEmpty } from '@/utils/misc';

export const createLayerGroup = (sceneEntity: SceneEntity, parent?: Layer | LayerGroup): LayerGroup | undefined => {
  if (!isEmpty(sceneEntity.id) && !isEmpty(sceneEntity.name)) {
    return {
      type: 'layerGroup',
      id: sceneEntity.id,
      name: sceneEntity.name,
      sceneEntityId: sceneEntity.id,
      parent,
    }
  }
}

export const createLayer = (sceneEntity: SceneEntity, layer: LayerQuery, parent?: Layer | LayerGroup): Layer | undefined => {
  if (
    !isEmpty(sceneEntity.renderObject) && 
    !isEmpty(layer) &&
    !isEmpty(layer.id) &&
    !isEmpty(layer.captureDate) &&
    !isEmpty(layer.name) &&
    !isEmpty(layer.mainSceneEntityId) &&
    !isEmpty(layer.supportingSceneEntityIds) &&
    !isEmpty(layer.worldPosition) &&
    !isEmpty(layer.worldRotation) &&
    !isEmpty(layer.formatType)
  ) {
    return {
      type: 'layer',
      id: layer.id,
      captureDate: new Date(layer.captureDate),
      name: layer.name,
      sceneEntityId: layer.mainSceneEntityId,
      supportingSceneEntityIds: layer.supportingSceneEntityIds as string[],
      formatType: layer.formatType as LayerFormatType,
      parent,
    };
  }
};

export const createPhotoGroup = (
  sceneEntity: SceneEntity,
  parent?: Layer | LayerGroup,
): PhotoGroup | undefined => {
  if (
    !isEmpty(sceneEntity.id) &&
    !isEmpty(sceneEntity.renderObject) &&
    !isEmpty(sceneEntity.position) &&
    !isEmpty(sceneEntity.rotation)
  ) {
    switch (sceneEntity.renderObject.__typename) {
      case 'ImageProjectionRenderObject':
        return {
          type: 'photoGroup',
          id: sceneEntity.id,
          name: sceneEntity.name ?? `2D Images (${sceneEntity.id})`,
          sceneEntityId: sceneEntity.id,
          parent,
        };
      case 'PanoramicRenderObject':
        return {
          type: 'photoGroup',
          id: sceneEntity.id,
          name: sceneEntity.name ?? `Panoramas (${sceneEntity.id})`,
          sceneEntityId: sceneEntity.id,
          parent,
        };
      default:
        return;
    }
  }
};

export const createAnnotationGroup = (
  sceneEntity: SceneEntity,
  parent: Layer | LayerGroup | undefined,
): AnnotationGroup | undefined => {
  if (
    !isEmpty(sceneEntity.renderObject) &&
    sceneEntity.renderObject.__typename === 'AnnotationRenderObject' &&
    !isEmpty(sceneEntity.renderObject.id) &&
    !isEmpty(sceneEntity.renderObject.createdAt) &&
    !isEmpty(sceneEntity.id) &&
    !isEmpty(sceneEntity.name) &&
    !isEmpty(sceneEntity.position) &&
    !isEmpty(sceneEntity.rotation)
  ) {
    return {
      type: 'annotationGroup',
      id: sceneEntity.renderObject.id,
      name: sceneEntity.name,
      sceneEntityId: sceneEntity.id,
      parent,
    };
  }
};
