import {
  createAnnotationGroup,
  createLayer,
  createLayerGroup,
  createPhotoGroup,
} from '@/components/SceneEntityTree/transformers';
import { LayerFormatType } from '@/constants/layer';
import { RENDERER_TYPE } from '@/constants/renderer';
import { SceneEntity } from '@/graphql/codegen/graphql';
import { useListLayerByProjectId } from '@/hooks/useListLayerByProjectId';
import { useListSceneEntitiesByProjectIdAndRendererTypesQuery } from '@/hooks/useListSceneEntitiesByProjectIdAndRendererTypesQuery';
import { isEmpty } from '@/utils/misc';
import { Graph } from '@skand/math';
import { useCallback, useMemo } from 'react';

export interface LayerGroup {
  type: 'layerGroup';
  id: string;
  name: string;
  sceneEntityId: string;
  parent?: Layer | LayerGroup;
}

export interface Layer {
  type: 'layer';
  id: string;
  name: string;
  captureDate: Date;
  sceneEntityId: string;
  supportingSceneEntityIds: string[];
  formatType: LayerFormatType;
  parent?: Layer | LayerGroup;
}

export interface AnnotationGroup {
  type: 'annotationGroup';
  id: string;
  name: string;
  sceneEntityId: string;
  parent?: Layer | LayerGroup;
}

export interface PhotoGroup {
  type: 'photoGroup';
  id: string;
  name: string;
  sceneEntityId: string;
  parent?: Layer | LayerGroup;
}

export interface SceneEntityNode {
  entity: LayerGroup | Layer | AnnotationGroup | PhotoGroup;
  children: SceneEntityNode[];
}

export const useSceneEntityData = ({
  projectId,
  filterNodeTypes,
}: {
  projectId?: string;
  filterNodeTypes: string[];
}) => {
  const {
    sceneEntities,
    response: { isFetching: isFetchingSceneEntities },
  } = useListSceneEntitiesByProjectIdAndRendererTypesQuery({
    projectId: projectId,
  });
  const {
    layers: layersQueryData,
    response: { isFetching: isFetchingLayers },
  } = useListLayerByProjectId({
    projectId: projectId,
  });

  const isLoadingTreeData = isFetchingSceneEntities || isFetchingLayers;

  const applyNodeTypeFilters = useCallback(
    (list: SceneEntityNode[]) => {
      return list
        .map(item => {
          return { ...item };
        })
        .filter(item => {
          if ('children' in item) {
            item.children = applyNodeTypeFilters(item.children);
          }
          let filteredItem;
          if (filterNodeTypes.includes(item.entity.type)) {
            filteredItem = item;
          }
          if (filterNodeTypes.includes('layerGroup') && item.entity.type === 'layerGroup') {
            filteredItem = item;
          }
          if (item.entity.type == 'layer' && filterNodeTypes.includes(item.entity.formatType)) {
            filteredItem = item;
          }
          return filteredItem;
        });
    },
    [filterNodeTypes],
  );

  const treeData: SceneEntityNode[] = useMemo(() => {
    const layerGroups: LayerGroup[] = [];
    const layers: Layer[] = [];
    const annotationGroups: AnnotationGroup[] = [];
    const panoramaGroups: PhotoGroup[] = [];
    const photo2DGroups: PhotoGroup[] = [];

    // Link nodes together
    const graph = new Graph<string>();
    const sceneEntityMap = new Map<string, SceneEntity>();
    const objectMap = new Map<string, Layer | LayerGroup | AnnotationGroup | PhotoGroup>();

    // Pre-process scene entity graph
    for (const entity of sceneEntities) {
      if (!isEmpty(entity) && !isEmpty(entity.id)) {
        graph.addNode(entity.id);
        sceneEntityMap.set(entity.id, entity);

        // Define dependency
        if (!isEmpty(entity.parentSceneEntityId)) {
          graph.addEdge(entity.parentSceneEntityId, entity.id);
        }
      }
    }

    // Process each node in order
    const sorted = graph.topologicalSort() ?? [];
    
    for (const nodeId of sorted) {
      const parentId = graph.getParents(nodeId)[0];
      const node = sceneEntityMap.get(nodeId);
      const parent = objectMap.get(parentId) as Layer | undefined;
      
      if (!node || (parentId && !parent)) {
        continue;
      }

      // Create the nodes by renderer type
      if (node.rendererType === null) {
        const group = createLayerGroup(node, parent);
        if (group) {
          layerGroups.push(group);
          objectMap.set(nodeId, group);
        }
      } else if (node.rendererType === RENDERER_TYPE.ANNOTATION) {
        const group = createAnnotationGroup(node, parent);
        if (group) {
          annotationGroups.push(group);
          objectMap.set(nodeId, group);
        }
      } else if (node.rendererType === RENDERER_TYPE.IMAGE_PROJECTION) {
        const group = createPhotoGroup(node, parent);
        if (group) {
          photo2DGroups.push(group);
          objectMap.set(nodeId, group);
        }
      } else if (node.rendererType === RENDERER_TYPE.PANORAMIC_IMAGE) {
        const group = createPhotoGroup(node, parent);
        if (group) {
          panoramaGroups.push(group);
          objectMap.set(nodeId, group);
        }
      } else {
        const layerQuery = layersQueryData.find(
          layer => layer?.mainSceneEntityId === nodeId,
        );

        if (layerQuery) {
          const layer = createLayer(node, layerQuery, parent);
          if (layer) {
            layers.push(layer);
            objectMap.set(nodeId, layer);
          }
        }
      }
    }

    const entities = [
      ...layerGroups,
      ...layers,
      ...annotationGroups,
      ...photo2DGroups,
      ...panoramaGroups,
    ];

    const nodeMap = new Map<(typeof entities)[number], SceneEntityNode>();
    for (const entity of entities) {
      const node: SceneEntityNode = { entity, children: [] };
      nodeMap.set(entity, node);
    }

    const nodes: SceneEntityNode[] = [];
    for (const entity of entities) {
      const node = nodeMap.get(entity);
      if (node) {
        if (entity.parent) {
          const parent = nodeMap.get(entity.parent);
          if (parent) {
            parent.children.push(node);
          }
        } else {
          nodes.push(node);
        }
      }
    }

    const filteredNodes = applyNodeTypeFilters(nodes);
    return filteredNodes;
  }, [sceneEntities, layersQueryData, applyNodeTypeFilters]);

  return { treeData, isLoadingTreeData };
};
