import { FIND_SCENE_ENTITIES_BY_IDS } from '@/graphql/queries';
import { SceneEntity } from '@/graphql/codegen/graphql';
import { request } from '@/graphql/request';
import { hasId } from '@/utils/misc';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const getQueryKey = (sceneEntityIds: string[]) => ['FIND_SCENE_ENTITIES_BY_IDS', sceneEntityIds];

export const useFindSceneEntitiesByIds = (sceneEntityIds: string[]) => {
  const response = useQuery({
    enabled: sceneEntityIds.length > 0,
    queryFn: () =>
      request(FIND_SCENE_ENTITIES_BY_IDS, {
        sceneEntityIds,
      }),
    queryKey: getQueryKey(sceneEntityIds),
  });

  const sceneEntities: SceneEntity[] = useMemo(() => {
    return response.data?.findSceneEntitiesByIds?.filter(hasId) ?? [];
  }, [response.data?.findSceneEntitiesByIds]);

  return {
    response,
    sceneEntities,
  };
};

useFindSceneEntitiesByIds.getQueryKey = getQueryKey;
