import { useReadUserByContext } from './useReadUserByContext';

export const useIsUserAdmin = () => {
  const { user, response } = useReadUserByContext();

  return {
    isAdmin: response.isFetching ? true : user?.isAdmin ?? false,
    isFetching: response.isFetching,
  };
};
