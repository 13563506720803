import { SKAND_SUPPORT_EMAIL } from '@/constants/env';
import { cn } from '@/utils/classname';

export const NoRegionPage = () => {
  return (
    <div
      className={cn(
        'min-h-screen',
        'w-full',
        'flex',
        'items-center',
        'justify-center',
        'bg-neutral-100',
        'overflow-hidden',
      )}
    >
      <div className="w-[500px] text-neutral-500">
        <h1 className="typo-heading-2">Oops!</h1>
        <p className="mt-5 typo-text-m">
          Your account has not been assigned a region yet. Please contact your administrator to set
          up your region and gain access.
        </p>
        <p className="mt-3 typo-text-m">
          If you believe this is an error, reach out to our support team at{' '}
          <a
            className="color-primary-400 no-underline typo-link-m"
            href={`mailto:${SKAND_SUPPORT_EMAIL}`}
          >
            {SKAND_SUPPORT_EMAIL}
          </a>
          .
        </p>
      </div>
    </div>
  );
};
